import React from "react";
import { notification, Tag, Button } from "antd";
import { Table } from "ant-table-extensions";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Auxiliary from "util/auxiliary";

import {
  listCustomerGameReceived,
  searchGameById,
  resetCustomerGameReceived,
} from "./../../../appRedux/actions/customerGamesReceived";

let fields = {
  selectedDate: {
    header: "Sent At",
    formatter: (_fieldValue, record) => {
      return moment(record?.selectedDate).format("MMM-DD-YYYY");
    },
  },
  gameNumber: {
    header: "Game ID#",
    formatter: (_fieldValue, record) => {
      return record?.gameNumber;
    },
  },
  inventoryId: {
    header: "Game Name",
    formatter: (_fieldValue, record) => {
      return (
        record?.inventoryId?.gameName
      );
    },
  },
  giftMoney: {
    header: "Gift Money",
    formatter: (_fieldValue, record) => {
        return "$" + record?.giftMoney;
    },
  },
  sender: {
    header: "Sender",
    formatter: (_fieldValue, record) => {
      return (
        record?.sender.firstName +
        " " +
        record?.sender.lastName +
        "\n" +
        record?.sender?.email +
        "\n" +
        record?.sender?.dialCode +
        " " +
        record?.sender?.phone
      );
    },
  },
  event: {
    header: "Event",
    formatter: (_fieldValue, record) => {
      return (
        record?.event?.name
      );
    },
  },
  greetingPackage: {
    header: "Greeting Package",
    formatter: (_fieldValue, record) => {
      return (
        record?.greetingPackage?.name
      );
    },
  }
};

const renderPlayingStatus = (status) => {
  let statusTag;
  switch (status) {
    case 1:
      statusTag = <Tag color="processing">In Progress</Tag>;
      break;
    case 2:
      statusTag = <Tag color="warning">Paused</Tag>;
      break;
    case 3:
      statusTag = <Tag color="success">Completed</Tag>;
      break;
    case 4:
      statusTag = <Tag color="geekblue">Keyzone</Tag>;
      break;
    default:
      statusTag = <Tag color="default">Not Started</Tag>;
      break;
  }
  return statusTag;
};

const getRandomuserParams = (params) => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === "prev") {
    return (
      <Button className="ant-paginatio-button">
        <MdNavigateBefore className="icon-size" /> Previous
      </Button>
    );
  }
  if (type === "next") {
    return (
      <Button className="ant-paginatio-button">
        Next <MdNavigateNext className="icon-size" />
      </Button>
    );
  }
  return originalElement;
};

class GamesReceivedTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      record: null,
      receivedGamesList: [],
      pagination: {
        current: 1,
        pageSize: 10,
      },
    };

    this.columns = [
      {
        title: "Sent At",
        dataIndex: "selectedDate",
        key: "selectedDate",
        width: 150,
        sorter: (a, b) =>
          moment(a.selectedDate).unix() - moment(b.selectedDate).unix(),
        sortDirections: ["descend", "ascend"],
        render: (selectedDate) => {
          return <span>{moment(selectedDate).format("MMM-DD-YYYY")}</span>;
        },
      },
      {
        title: "Game ID#",
        dataIndex: "gameNumber",
        key: "gameNumber",
        sorter: (a, b) => a.gameNumber - b.gameNumber,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Game Name",
        dataIndex: "inventoryId",
        key: "inventoryId",
        width: 150,
        render: (inventoryId) => {
          return <span>{inventoryId?.gameName}</span>;
        },
      },
      {
        title: "Gift Money",
        dataIndex: "giftMoney",
        key: "giftMoney",
        sorter: (a, b) => a.giftMoney - b.giftMoney,
        sortDirections: ["descend", "ascend"],
        render: (giftMoney) => {
          return (
            <>
              <span>${giftMoney}</span>
            </>
          );
        },
      },
      {
        title: "Sender",
        dataIndex: "sender",
        key: "sender",
        sorter: (a, b) => a.sender.firstName.length - b.sender.firstName.length,
        sortDirections: ["descend", "ascend"],
        render: (sender) => {
          return (
            <>
              <span>
                {sender?.firstName} {sender?.lastName}
              </span>
              <br />
              <span>{sender?.email}</span>
              <br />
              <span>
                {sender?.dialCode} {sender?.phone}
              </span>
            </>
          );
        },
      },
      {
        title: "Event",
        dataIndex: "event",
        key: "event",
        width: 150,
        render: (event) => {
          return <span>{event?.name}</span>;
        },
      },
      {
        title: "Greeting Package",
        dataIndex: "greetingPackage",
        key: "greetingPackage",
        width: 150,
        render: (greetingPackage) => {
          return <span>{greetingPackage?.name}</span>;
        },
      },
      {
        title: "Playing",
        dataIndex: "playingStatus",
        key: "playingStatus",
        sorter: (a, b) => a.playingStatus - b.playingStatus,
        sortDirections: ["descend", "ascend"],
        render: (playingStatus) => {
          return renderPlayingStatus(playingStatus);
        },
        filters: [
          {
            text: "Not Started",
            value: 0,
          },
          {
            text: "In Progress",
            value: 1,
          },
          {
            text: "Paused",
            value: 2,
          },
          {
            text: "Completed",
            value: 3,
          },
          {
            text: "Keyzone",
            value: 4,
          },
        ],
        onFilter: (value, record) => record.playingStatus === value,
      },
    ];
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        recipient: this.props.userId,
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize,
      };
      this.props.listCustomerGameReceived(authUser.token, inputJson);
    }
  };

  onSearch = (value) => {
    if (value) {
      const { pagination } = this.state;
      let inputJson = {
        user: this.props.userId,
        searchString: value,
        current: pagination.current,
        pageSize: pagination.pageSize,
      };
      const { authUser } = this.props.auth;
      this.props.searchGameById(inputJson, authUser.token);
    }
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.customerGamesReceivedData) {
      if (nextProps.customerGamesReceivedData.messageId === 200) {
        nextProps.resetCustomerGameReceived();
        return {
          receivedGamesList: nextProps.customerGamesReceivedData.lists.data,
          pagination: {
            current: nextProps.customerGamesReceivedData.lists.current,
            pageSize: nextProps.customerGamesReceivedData.lists.pageSize,
            total: nextProps.customerGamesReceivedData.lists.totalCount,
          },
        };
      } else if (nextProps.customerGamesReceivedData.messageId === 203) {
        notification["error"]({
          message: "Alert!!",
          description: nextProps.customerGamesReceivedData.alertMessage,
        });
        nextProps.resetCustomerGameReceived();
      }
      return true;
    }
    return null;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  render() {
    const { isLoading } = this.props.customerGamesReceivedData;
    const { pagination, receivedGamesList } = this.state;

    return (
      <Auxiliary>
        <Row className="row-margin2">
          <Col>
            <Table
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={receivedGamesList}
              rowKey={(record) => record._id}
              exportableProps={{
                fields,
                fileName: "games-received",
                showColumnPicker: true,
              }}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                itemRender: itemRender,
                size: "small",
              }}
              loading={isLoading}
              size={"middle"}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customerGamesReceivedData: state.customerGamesReceivedData,
  };
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    {
      listCustomerGameReceived,
      searchGameById,
      resetCustomerGameReceived,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispathToProps)(GamesReceivedTab);
