import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  DatePicker,
  Form,
  Modal,
  notification,
  Select,
} from "antd";
import { activeTokkenzType } from "../../../appRedux/actions/tokkenzType";
const _ = require('lodash');

const FormItem = Form.Item;
const { Option } = Select;

const perksList = [{
  perkName: 'PERK 1',
  perkValue: 25,
  years: 1,
  membershipType: 'Bronze Membership',
  freeGames: 6,
  discountPercentage: 20,
  freeGamesCostUpto: 48,
  freeGamesUsed: 0
}, {
  perkName: 'PERK 2',
  perkValue: 50,
  years: 1,
  membershipType: 'Bronze Membership',
  freeGames: 15,
  discountPercentage: 20,
  freeGamesCostUpto: 120,
  freeGamesUsed: 0
}, {
  perkName: 'PERK 3',
  perkValue: 75,
  years: 1,
  membershipType: 'Silver Membership',
  freeGames: 20,
  discountPercentage: 25,
  freeGamesCostUpto: 160,
  freeGamesUsed: 0
}, {
  perkName: 'PERK 4',
  perkValue: 100,
  years: 1,
  membershipType: 'Silver Membership',
  freeGames: 25,
  discountPercentage: 24,
  freeGamesCostUpto: 200,
  freeGamesUsed: 0
}, {
  perkName: 'PERK 5',
  perkValue: 200,
  years: 2,
  membershipType: 'Gold Membership',
  freeGames: 30,
  discountPercentage: 30,
  freeGamesCostUpto: 240,
  freeGamesUsed: 0
}, {
  perkName: 'PERK 6',
  perkValue: 500,
  years: 2,
  membershipType: 'Gold Membership',
  freeGames: 30,
  discountPercentage: 30,
  freeGamesCostUpto: 240,
  freeGamesUsed: 0
}, {
  perkName: 'PERK 7',
  perkValue: 2500,
  years: 2,
  membershipType: 'Gold Membership',
  freeGames: 50,
  discountPercentage: 75,
  freeGamesCostUpto: 240,
  freeGamesUsed: 0
}, {
  perkName: 'PERK 8',
  perkValue: 5000,
  years: 2,
  membershipType: 'Gold Membership',
  freeGames: 50,
  discountPercentage: 75,
  freeGamesCostUpto: 240,
  freeGamesUsed: 0
}]

class AssignIndieGogoPerk extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { authUser } = this.props.auth;
    if (authUser) {
      this.props.activeTokkenzType(authUser.token);
    }
  }

  onFinish = (values) => {
    let result = _.find(perksList, function (obj) {
      if (obj.perkName === values.type) {
        return true;
      }
    });
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const { authUser } = this.props.auth;
        let indiegogoPerk = result;
        indiegogoPerk.datePurchased = new Date(values.datePurchased);
        const inputJSON = {
          _id: this.props.ecommerceUserId,
          indiegogoPerk: indiegogoPerk
        };
        fetch(`${process.env.REACT_APP_API_URL}app-user/update-indiegogo-perk`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer ".concat(authUser.token),
          },
          body: JSON.stringify(inputJSON),
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.messageId === 200) {
              this.setState({
                isLoading: false,
              });
              notification["success"]({
                message: "Success",
                description: json.message,
              });
              this.formRef.current.setFieldsValue({
                type: null,
                count: null,
              });
              this.props.onClose();
              window.location.reload();
            } else {
              this.setState({
                isLoading: false,
              });
              notification["error"]({
                message: "Alert!!",
                description: json.message,
              });
            }
          });
      }
    );
  };

  render() {
    const { onClose, open } = this.props;
    const { isLoading } = this.state;
    return (
      <Modal
        onCancel={onClose}
        open={open}
        title="Select INDIEGOGO PERK"
        closable={true}
        style={{ zIndex: 2600 }}
        footer={null}
      >
        <Form
          layout={"vertical"}
          onFinish={this.onFinish}
          className="gx-signin-form gx-form-row0"
          ref={this.formRef}
          autoComplete="off"
        >
          <FormItem
            label="Select PERK"
            name="type"
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
            ]}
          >
            <Select placeholder="Select PERK">
              {perksList?.map((item) => (
                <Option key={item.perkName}>{item.perkName} (Value: ${item.perkValue})</Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label="Date Purchased" rules={[{ required: true, message: 'Please select purchased date.' }]} name="datePurchased">
            <DatePicker style={{ width: '100%' }} format={'MM-DD-YYYY'} />
          </FormItem>
          <FormItem>
            <Button
              loading={isLoading}
              className="gx-mb-0"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    tokkenzTypeData: state.tokkenzTypeData,
  };
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    {
      activeTokkenzType,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispathToProps)(AssignIndieGogoPerk);
