import React from "react";
import { connect } from "react-redux";
import {
    Button,
    Modal,
    Upload,
    notification,
} from "antd";
import axios from 'axios';
import { UploadOutlined } from "@ant-design/icons";

class UploadUsers extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            fileList: []
        };
        this.formRef = React.createRef();
    }

    handleUpload = async () => {
        const { fileList } = this.state;
        const { authUser } = this.props.auth;
        this.setState({ isLoading: true });
        let formData = new FormData()
        formData.append('file', fileList[0]);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '.concat(authUser.token)
            }
        });
        const response = await INSTANCE.post('/app-user/upload', formData);
        const data = await response.data;
        if (data.messageId === 200) {
            notification['success']({
                message: 'Success',
                description: data.message
            });
            this.setState({ isLoading: false });
            this.props.onClose();
        } else {
            this.setState({ isLoading: false });
            notification['error']({
                message: 'Alert!!',
                description: data.message
            });
            this.props.onClose();
        }

    };

    beforeUpload = file => {
        this.setState(state => ({
            fileList: [...state.fileList, file],
        }));
        return false;
    }

    onRemove = file => {
        this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList,
            };
        });
    }

    render() {
        const { onClose, open } = this.props;
        const { isLoading, fileList } = this.state;

        const uploadButton = (
            <Button icon={<UploadOutlined />}>Upload CSV</Button>
        );

        return (
            <Modal
                onCancel={onClose}
                open={open}
                title="Upload Users"
                closable={true}
                style={{ zIndex: 2600 }}
                footer={null}
            >
                <Upload
                    listType="picture"
                    beforeUpload={this.beforeUpload}
                    onRemove={this.onRemove}
                    fileList={fileList}
                    accept=".csv"
                >
                    {fileList.length > 0 ? null : uploadButton}
                </Upload>
                <Button
                    type="primary"
                    className="add-btn-row"
                    onClick={this.handleUpload}
                    disabled={fileList.length === 0}
                    loading={isLoading}>
                    {isLoading ? 'Uploading' : 'Start Upload'}
                </Button>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, null)(UploadUsers);
